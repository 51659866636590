<template>
  <div class="news">
    <head-nav></head-nav>
    <about-img></about-img>
    <about-nav
      :aboutBreadcrumb="breadcrumb"
      :toback-list="breadcrumbFirst"
    ></about-nav>

    <div class="wrap">
      <h3 class="news-title">
        <img
          :src="$t('about.title-10')"
          alt=""
        />
      </h3>
      <div
        class="news-info"
        v-html="listcontent[0].content"
      ></div>
    </div>
    <div class="news-content">
      <div class="wrap">
        <h3 class="cooperative-title">
          <img
            :src="$t('about.title-11')"
            alt=""
          >
        </h3>

        <div class="cooperative-box">
          <div
            class="cooperative-list"
            v-for="(item, index) in listimg"
            :key="index"
          >
            <img
              :src="item.image.publicUrl"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';

import returnImg from '@/assets/image/product/fanhui.png';
import productContent from '@/mock/content';
import HeadNav from './head-nav';
import AboutImg from './about-img';
import Footers from './footers';
import AboutNav from './about-nav';

export default {
  components: {
    HeadNav,
    AboutImg,
    Footers,
    AboutNav,
  },
  apollo: {
    listcontent: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              listcontent: allSupplierCooperations {
                content
              }
            }
          `;
        }
        return gql`
          query {
            listcontent: allEnSupplierCooperations {
              content
            }
          }
        `;
      },
    },
    listimg: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              listimg: allCooperativePartners {
                image {
                  publicUrl
                }
              }
            }
          `;
        }
        return gql`
          query {
            listimg: allEnCooperativePartners {
              image {
                publicUrl
              }
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      productContent,
      listcontent: [
        {
          content: '',
        },
      ],
      listimg: [],
      breadcrumb: [
        {
          label: 'about.cooperation',
        },
      ],
      breadcrumbFirst: [
        {
          path: '/about',
          label: 'toback',
          image: returnImg,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.news {
  .wrap {
    padding: 88px 0 150px 0;

    .news-title {
      text-align: center;
      margin-top: 30px;
      font-size: 24px;
      font-weight: 500;
    }

    .news-info {
      margin-top: 40px;
      ::v-deep p {
        color: #555;
        font-size: 14px;
        line-height: 20px;
      }

      ::v-deep table {
        border: 1px solid #eeeeee;
        width: 100% !important;

        tbody {
          tr {
            background: #f8f8f8;
            padding: 10px 0;

            td {
              padding: 10px 0;
              color: #666;
            }
          }

          tr:nth-child(2n + 2) {
            background: #fff;
          }
        }
      }
    }
  }

  .news-content {
    padding: 90px 0 120px 0;
    background: #eeeeee;

    .wrap {
      .cooperative-title {
        text-align: center;
      }

      .cooperative-box {
        margin-top: 50px;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;

        .cooperative-list {
          width: 220px;
          height: 150px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .news-content {
    width: 1200px;
  }
}
</style>
